import { Chip, Grid } from "@mui/material";
import { deleteObject, StorageReference } from "firebase/storage";
import { FC, useContext, useState } from "react";
import { GenericDialog } from "../../common/GenericDialog";
import { CloudItemProps, DocFile, Metadata } from "./CloudItem";
import { getFileChip } from "./getFileChip";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";

export type CloudItemListProps = CloudItemProps & {
  selectedDictionaries: string[];
  setSelectedDictionaries: (dictionaries: string[]) => void;
  storageTable: { [key: string]: StorageReference };
  toggleText: (doc: DocFile & Metadata, item: StorageReference) => void;
  getStorageList: () => void;
};

export const CloudItemList: FC<CloudItemListProps> = (props) => {
  const {
    fileListData,
    setFileListData,
    selectedDictionaries,
    storageTable,
    toggleText,
  } = props;

  const [deleteKey, setDeleteKey] = useState<string | null>(null);
  const { enabledUserDictionary } = useContext(EnigmaStudioContext);

  return (
    <Grid container sx={{ mt: 1 }}>
      {storageTable && fileListData?.files && (
        <>
          {Object.keys(fileListData.files).map((key) => {
            const file = fileListData.files[key];
            if (!file) return null;
            if (file.type !== "text") return null;
            const item = storageTable[file.fileName];
            const { color, variant } = getFileChip(
              selectedDictionaries.includes("user:" + file.fileName)
                ? "selected"
                : enabledUserDictionary.find((e) => e.key === file.fileName)
                ? "loaded"
                : "unload"
            );
            if (!item) {
              return null;
            }
            return (
              <Chip
                key={key}
                label={file.name}
                onClick={() => {
                  toggleText(file as DocFile & Metadata, item);
                }}
                onDelete={() => {
                  setDeleteKey(key);
                }}
                color={color}
                variant={variant}
                sx={{
                  mb: 1,
                  mr: 1,
                  cursor: "pointer",
                }}
              />
            );
          })}
          <GenericDialog
            open={deleteKey !== null}
            title="削除確認"
            description={`『${
              fileListData.files[deleteKey ?? ""]?.name ?? ""
            }』をクラウドから削除しますか？`}
            agreeText="削除"
            disagreeText="キャンセル"
            onAgree={() => {
              if (!deleteKey) return;
              const file = fileListData.files[deleteKey];
              const deleteItem = storageTable[file.fileName];
              deleteObject(deleteItem).then(() => {
                if (setFileListData) {
                  const newFiles = { ...fileListData.files };
                  delete newFiles[deleteKey];
                  setFileListData({ files: newFiles });
                }
              });
              setDeleteKey(null);
            }}
            onDisagree={() => {
              setDeleteKey(null);
            }}
            onClose={() => {
              setDeleteKey(null);
            }}
          />
        </>
      )}
    </Grid>
  );
};
