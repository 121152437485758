import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useContext } from "react";
import { useFirebaseLogin } from "../../../../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { NewDictionarySelector } from "../../../../commonStudio/NewDictionarySelector";
import { EnigmaStudioContext } from "../../../../context/EnigmaStudioContext";
import { WordList } from "../../../Tools/WordList";
import { CloudDoc, CloudItem } from "../../../CloudItem/CloudItem";
import { ConvertTimestampRecursively } from "../../../../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { debug } from "../../../../../../debug";

type GeneralSeachDictionarySelectorProps = {
  inputTabValue: string;
  setInputTabValue: (value: string) => void;
  dictionaries: string[];
  setDictionaries: (dictionaries: string[]) => void;
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
  fileListLoading: boolean;
};

export const GeneralSeachDictionarySelector: FC<
  GeneralSeachDictionarySelectorProps
> = ({
  inputTabValue,
  setInputTabValue,
  dictionaries,
  setDictionaries,
  fileListData,
  setFileListData,
  fileListLoading,
}) => {
  const ctx = useContext(EnigmaStudioContext);
  const { user } = useFirebaseLogin();

  // Input Tab
  const handleInputTabChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: string
  ) => {
    setInputTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <ToggleButtonGroup
          color="primary"
          value={inputTabValue}
          exclusive
          onChange={handleInputTabChange}
        >
          <ToggleButton value="dictionary">入力辞書選択</ToggleButton>
          <ToggleButton value="free">自由入力</ToggleButton>
          {user &&
            ["cloudplus", "biz", "biz-trial"].includes(
              ctx.plan ?? debug.role
            ) && (
              <ToggleButton value="cloudFile">クラウドファイル</ToggleButton>
            )}
        </ToggleButtonGroup>
      </Box>

      {inputTabValue === "dictionary" && (
        <NewDictionarySelector
          selectedDictionaries={dictionaries}
          setSelectedDictionaries={setDictionaries}
          displayLabel={false}
        />
      )}
      <CloudItem
        tabValue={inputTabValue}
        userId={user?.uid}
        selectedDictionaries={dictionaries}
        setSelectedDictionaries={setDictionaries}
        fileListData={fileListData}
        setFileListData={setFileListData}
        fileListLoading={fileListLoading}
      />
      <Box
        sx={{ pt: 1, pb: 2, mb: 3, borderBottom: 1, borderColor: "divider" }}
      >
        {inputTabValue === "free" && <WordList />}
      </Box>
    </>
  );
};
