import {
  FunctionSearchProgress,
  GeneralSearchQuery,
  GeneralSearchResultUnit,
} from "@langue-de-chat-llc/enigmastudio-algorithm";
import CasinoIcon from "@mui/icons-material/Casino";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FilterListIcon from "@mui/icons-material/FilterList";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { BarChart, BarSeriesType } from "@mui/x-charts";
import { useSnackbar } from "notistack";
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { useStatePersist } from "use-state-persist";
import { useWorker } from "../../../hooks/useWorker";
import { splitIgnoringBraces } from "../../../lib/splitIgnoringBraces";
import { EnigmaStudioContext } from "../context/EnigmaStudioContext";
import { ref, uploadBytes } from "firebase/storage";
import { useFirebaseConfig } from "../../../_lib/eniverse/EniverseProvider";
import { useFirebaseLogin } from "../../../_lib/eniverse/siteKit/auth/useFirebaseLogin";
import { randomKeyGen } from "../../../_lib/eniverse/util/keygen";
import { CloudDoc } from "../Studio/CloudItem/CloudItem";
import { serverTimestamp } from "firebase/firestore";
import { ConvertTimestampRecursively } from "../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { debug } from "../../../debug";

const ProgressChart = ({
  progress,
  size,
}: {
  progress: FunctionSearchProgress;
  size: number;
}) => {
  if (!progress) {
    return <></>;
  }
  if (progress.startTime === 0) {
    return <></>;
  }
  if (!progress.queryProgress) {
    return <></>;
  }
  const arr = Array(size)
    .fill(0)
    .map((_, i) => i);

  const series = [
    {
      stack: "",
      data: progress.queryProgress.map((q) => q.currentWordCount),
    },
    {
      stack: "",
      data: progress.queryProgress.map(
        (q) => q.totalWordCount - q.currentWordCount
      ),
    },
  ] as BarSeriesType[];

  return (
    <BarChart
      series={series}
      layout="horizontal"
      height={100 + size * 80}
      width={400}
      margin={{ top: 70 }}
      yAxis={[
        {
          id: "queries",
          data: arr.map((_, i) => i + 1),
          scaleType: "band",
          valueFormatter: (value) => value.toString(),
        },
      ]}
      xAxis={[
        {
          scaleType: "linear",
        },
      ]}
    />
  );
};

const renderRow = (props: ListChildComponentProps<string[] | ReactNode[]>) => {
  const { index, style, data } = props;

  return (
    <ListItem
      style={style}
      key={index}
      component="div"
      disablePadding
      sx={{ my: 0, py: 0 }}
    >
      <ListItemText
        primary={data[index]}
        sx={{ my: 0, py: 0, whiteSpace: "nowrap" }}
      />
    </ListItem>
  );
};

export type ResultProps = {
  items: (GeneralSearchResultUnit | string)[];
  loading?: boolean;
  progress?: FunctionSearchProgress;
  queries: GeneralSearchQuery[];
  simple?: boolean;
  setFreeInput?: (value: string) => void;
  reset?: () => void;
  result?: { [key: string]: string[] };
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
};

type SortType = "asc" | "desc" | "asclen" | "desclen";

export const GeneralSearchResultList: FC<ResultProps> = ({
  items,
  loading,
  progress,
  queries,
  simple,
  setFreeInput,
  reset,
  fileListData,
  setFileListData,
}) => {
  const { enabledDictionary, setLoaded, plan } =
    useContext(EnigmaStudioContext);
  const { user } = useFirebaseLogin();
  const worker = useWorker();
  const { enqueueSnackbar } = useSnackbar();

  const toWord = (item: GeneralSearchResultUnit | string) => {
    if (typeof item === "string") {
      return item;
    }
    return item.s;
  };
  const toWordList = (items: (GeneralSearchResultUnit | string)[]) => {
    return items.map(toStr);
  };
  const toValue = (item: GeneralSearchResultUnit | string) => {
    if (typeof item === "string") {
      return null;
    }
    return item.v;
  };
  const toStr = (item: GeneralSearchResultUnit | string) => {
    if (typeof item === "string") {
      return item;
    }
    return item.v?.toFixed(2) + ":" + item.s;
  };

  const loadCustom = (items: (GeneralSearchResultUnit | string)[]) => {
    const wordList = items.map(toWord);
    worker.loadCustomArr(wordList, (result: string[] | undefined) => {
      if (result?.sort().join(",") === enabledDictionary.sort().join(",")) {
        setLoaded(true);
      }
    });
  };

  const [displayMonitor, setDisplayMonitor] = useStatePersist(
    "grid-result-display-monitor",
    false
  );
  const [pattern, setPattern] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [sortKey, setSortKey] = useState("1");
  const [sortType, setSortType] = useState("asc" as SortType);
  const [displayFilter, setDisplayFilter] = useStatePersist(
    "grid-result-display-filter",
    false
  );
  const [displayFile, setDisplayFile] = useStatePersist(
    "grid-result-display-file",
    false
  );
  const [resultDisplayFilter, setResultDisplayFilter] = useState<boolean[]>([]);

  const [download, setDownload] = useState("");
  const handleDownload = useCallback(() => {
    setDownload(
      "data:text/plain;charset=utf-8," +
        encodeURIComponent(filteredItems.join("\n"))
    );
  }, [filteredItems]);

  useEffect(() => {
    if (!items) {
      return;
    }
    const reg = new RegExp(pattern, "ug");
    let filtered = items.filter((item) => toWord(item).match(reg));

    if (sortKey === "") {
      setFilteredItems(filtered);
      return;
    }
    filtered = filtered.sort((a, b) => {
      const aValue = toValue(a);
      const bValue = toValue(b);
      if (
        (aValue ?? null) !== null &&
        (bValue ?? null) !== null &&
        aValue !== bValue
      ) {
        return (bValue as number) - (aValue as number);
      }
      const aKey = splitIgnoringBraces(toWord(a))[parseInt(sortKey) - 1] ?? a;
      const bKey = splitIgnoringBraces(toWord(b))[parseInt(sortKey) - 1] ?? b;
      if (sortType === "asclen") {
        const aLen = aKey.length;
        const bLen = bKey.length;
        if (aLen !== bLen) {
          return aLen - bLen;
        }
      } else if (sortType === "desclen") {
        const aLen = aKey.length;
        const bLen = bKey.length;
        if (aLen !== bLen) {
          return bLen - aLen;
        }
      }
      if (["asc", "asclen", "desclen"].includes(sortType)) {
        return aKey.localeCompare(bKey);
      } else {
        return bKey.localeCompare(aKey);
      }
    });

    filtered = filtered.map((item) => {
      const arr = splitIgnoringBraces(toWord(item));
      const value = toValue(item) ?? "";
      const str = arr
        .filter((_, i) => {
          if (resultDisplayFilter[i] === false) {
            return false;
          }
          return true;
        })
        .join(",");
      if (value) {
        return value + ":" + str;
      }
      return str;
    });
    setFilteredItems(filtered);
  }, [pattern, sortKey, sortType, items, resultDisplayFilter]);

  const handleOpenRandomWordSnack = useCallback(() => {
    if (!filteredItems || filteredItems.length === 0) {
      return;
    }
    enqueueSnackbar(
      toWord(filteredItems[Math.floor(Math.random() * filteredItems.length)])
    );
  }, [enqueueSnackbar, filteredItems]);

  const handleSetFreeInput = useCallback(() => {
    if (!filteredItems || filteredItems.length === 0) {
      return;
    }
    setFreeInput?.(filteredItems.join("\n"));
  }, [filteredItems, setFreeInput]);

  const [saveName, setSaveName] = useState("");
  const [{ cloudStorage }] = useFirebaseConfig();

  const saveCloud = useCallback(() => {
    // アップロードする文字列
    /*
    if (!user) {
      return;
    }*/
    if (!cloudStorage) {
      return;
    }
    if (filteredItems.length <= 0) {
      return;
    }
    if (!setFileListData) {
      return;
    }
    if (!fileListData) {
      return;
    }

    setSaveName("");
    const textContent = filteredItems.join("\n");
    const blob = new Blob([textContent], { type: "text/plain" });

    if (blob.size > 1024 * 1024 * 30) {
      enqueueSnackbar("ファイルサイズが大きすぎます");
      return;
    }
    const path = "enigma-studio/" + (user?.uid ?? "guest");
    const saveFileName = randomKeyGen(20);
    // Cloud Storageの参照を作成
    const storageRef = ref(cloudStorage, `${path}/${saveFileName}.txt`);
    // ファイルをアップロード
    uploadBytes(storageRef, blob).then(() => {
      enqueueSnackbar("保存しました");
      setFileListData({
        files: {
          ...(fileListData?.files ?? {}),
          [saveFileName]: {
            name: saveName,
            fileName: `${saveFileName}.txt`,
            type: "text",
            size: blob.size,
            lineSize: filteredItems.length,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          },
        },
      });
    });
  }, [
    cloudStorage,
    filteredItems,
    setFileListData,
    fileListData,
    user?.uid,
    saveName,
    enqueueSnackbar,
  ]);

  if (loading || items == null) {
    return (
      <>
        <Typography mt={2} variant="body1">
          検索中...{" "}
          {progress &&
            ((progress.currentTime - progress.startTime) / 1000).toFixed(1)}
          s
        </Typography>
        {(loading || displayMonitor) &&
          progress &&
          progress.queryProgress &&
          progress.queryProgress.length > 0 && (
            <ProgressChart progress={progress} size={queries.length} />
          )}
      </>
    );
  }

  return (
    <>
      {
        <>
          <Typography mt={2} variant="h4">
            結果
          </Typography>
          <Typography mt={2} variant="body1">
            {!simple && !loading && items.length > 0 && (
              <ToggleButton
                value="monitor"
                selected={displayFilter}
                size="small"
                color="primary"
                onChange={() => {
                  setDisplayFilter(!displayFilter);
                }}
                sx={{ mr: 1 }}
              >
                <FilterListIcon />
              </ToggleButton>
            )}
            {!loading && items.length > 0 && (
              <ToggleButton
                value="random"
                selected={displayFile}
                size="small"
                color="primary"
                onChange={() => {
                  setDisplayFile(!displayFile);
                }}
                sx={{ mr: 1 }}
              >
                <FileCopyIcon />
              </ToggleButton>
            )}
            {!simple && !(loading || !progress || progress.startTime === 0) && (
              <ToggleButton
                value="monitor"
                selected={displayMonitor}
                size="small"
                color="primary"
                onChange={() => {
                  setDisplayMonitor(!displayMonitor);
                }}
                sx={{ mr: 1 }}
              >
                <MonitorHeartIcon />
              </ToggleButton>
            )}
            {!loading && items.length > 0 && (
              <ToggleButton
                value="random"
                size="small"
                color="primary"
                onChange={() => {
                  handleOpenRandomWordSnack();
                }}
                sx={{ mr: 1 }}
              >
                <CasinoIcon />
              </ToggleButton>
            )}
            {items.length}件
          </Typography>
        </>
      }
      {!loading && displayFile && (
        <>
          <Typography mt={2} variant="body1">
            {!loading && items.length > 0 && (
              <a
                href={download}
                download="download.txt"
                onClick={handleDownload}
              >
                ダウンロード
              </a>
            )}
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 2, mr: 2 }}
              onClick={() => {
                navigator.clipboard.writeText(filteredItems.join("\n"));
              }}
            >
              クリップボードにコピー
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, mr: 2 }}
              onClick={() => loadCustom(filteredItems as string[])}
            >
              カスタム辞書に設定
            </Button>
            {setFreeInput && (
              <Button
                variant="contained"
                sx={{ mt: 2, mr: 2 }}
                onClick={handleSetFreeInput}
              >
                自由入力に設定
              </Button>
            )}
          </Typography>
          {user &&
            ["cloudplus", "biz", "biz-trial"].includes(plan ?? debug.role) && (
              <Box>
                <TextField
                  label="保存名"
                  value={saveName}
                  sx={{ mt: 2 }}
                  onChange={(e) => {
                    setSaveName(e.target.value);
                  }}
                  disabled={filteredItems.length <= 0}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveCloud}
                  disabled={saveName === "" || filteredItems.length <= 0}
                  sx={{
                    mt: 3,
                    ml: 1,
                  }}
                >
                  クラウドに保存
                </Button>
              </Box>
            )}
        </>
      )}

      <Box sx={{ mb: 2 }}>
        {!loading &&
          displayMonitor &&
          (progress?.queryProgress ?? []).map((q, i) => {
            const time = ((q.endTime ?? q.startTime) - q.startTime) / 1000;
            return (
              <Box key={i} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1">
                  Query{i + 1}: {q.currentWordCount}
                  {"words "}
                </Typography>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  {time.toFixed(3)}s
                </Typography>
              </Box>
            );
          })}
      </Box>
      {!loading && items.length > 0 && displayFilter && (
        <>
          <Box sx={{ mb: 2 }}>
            {!simple && (
              <FormControl variant="outlined" sx={{ mb: 2 }}>
                <InputLabel>ソートキー</InputLabel>
                <Select
                  value={sortKey || ""}
                  onChange={(e) => setSortKey(e.target.value as string)}
                  sx={{
                    width: "130px",
                  }}
                >
                  {splitIgnoringBraces(toWord(items[0])).map((_, i) => (
                    <MenuItem key={i} value={i + 1}>
                      トレース:{i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl variant="outlined" sx={{ mb: 2 }}>
              <InputLabel>ソートタイプ</InputLabel>
              <Select
                value={sortType || ""}
                onChange={(e) => setSortType(e.target.value as SortType)}
                sx={{
                  width: "130px",
                }}
              >
                <MenuItem value="asc">昇順</MenuItem>
                <MenuItem value="desc">降順</MenuItem>
                <MenuItem value="asclen">文字数昇順</MenuItem>
                <MenuItem value="desclen">文字数降順</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="検索"
              variant="outlined"
              value={pattern || ""}
              onChange={(e) => setPattern(e.target.value)}
              sx={{ ml: 1, mb: 2 }}
            />
          </Box>
          {!simple && (
            <Box sx={{ mb: 2 }}>
              トレース表示:
              <Checkbox
                checked={resultDisplayFilter.every((v) => v === true)}
                indeterminate={
                  resultDisplayFilter.some((v) => v === true) &&
                  resultDisplayFilter.some((v) => v === false)
                }
                onChange={() => {
                  const checked = resultDisplayFilter.every((v) => v === true);
                  const newResultDisplayFilter = [...resultDisplayFilter];
                  newResultDisplayFilter.fill(!checked);
                  setResultDisplayFilter(newResultDisplayFilter);
                }}
              />
              <ButtonGroup size="small">
                {splitIgnoringBraces(toWord(items[0])).map((_, i) => (
                  <Button
                    variant={
                      resultDisplayFilter[i] ?? true ? "contained" : "outlined"
                    }
                    onMouseDown={(e) => {
                      const checked =
                        resultDisplayFilter[i] === undefined
                          ? true
                          : resultDisplayFilter[i];
                      const newResultDisplayFilter = [...resultDisplayFilter];
                      newResultDisplayFilter[i] = !checked;
                      setResultDisplayFilter(newResultDisplayFilter);
                    }}
                    size="small"
                  >
                    {i + 1}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          )}
        </>
      )}
      {!loading && (
        <Box
          sx={{
            width: "100%",
            height: 600,
            bgcolor: "background.paper",
          }}
        >
          <FixedSizeList
            height={600}
            width={"100%"}
            itemSize={24}
            itemCount={filteredItems.length}
            overscanCount={20}
            itemData={toWordList(filteredItems)}
          >
            {renderRow}
          </FixedSizeList>
        </Box>
      )}
    </>
  );
};
