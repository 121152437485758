import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import {
  getBlob,
  listAll,
  ListResult,
  ref,
  StorageReference,
} from "firebase/storage";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useFirebaseConfig } from "../../../../_lib/eniverse/EniverseProvider";
import { ConvertTimestampRecursively } from "../../../../_lib/eniverse/storekit/firestore/common/convertTimestamp";
import { useWorker } from "../../../../hooks/useWorker";
import { EnigmaStudioContext } from "../../context/EnigmaStudioContext";
import { CloudItemList } from "./CloudItemList";
import { CloudItemNew } from "./CloudItemNew";

type TextFile = {
  type: "text";
  separator?: string;
  lineSize?: number;
  headerRow?: number;
};

type GSQFile = {
  type: "general-search-query";
};

export type DocFile = {
  name: string;
  fileName: string;
  size: number;
} & (TextFile | GSQFile);

export type Metadata = {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export type CloudDoc = {
  files: {
    [key: string]: DocFile & Metadata;
  };
};

export type CloudItemProps = {
  userId: string;
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
};

type Props = {
  userId?: string;
  selectedDictionaries: string[];
  setSelectedDictionaries: (dictionaries: string[]) => void;
  tabValue: string;
  fileListData?: CloudDoc;
  setFileListData?: (data: ConvertTimestampRecursively<CloudDoc>) => void;
  fileListLoading: boolean;
};

export const CloudItem: FC<Props> = (props) => {
  const {
    userId: _userId,
    selectedDictionaries,
    setSelectedDictionaries,
    tabValue,
    fileListData,
    setFileListData,
    fileListLoading,
  } = props;
  const [mode, setMode] = useState<"list" | "new">("list");
  const userId = _userId ?? "guest";
  const [{ cloudStorage }] = useFirebaseConfig();
  const { loadText } = useWorker();
  const {
    enabledUserDictionary,
    setEnabledUserDictionary,
    multiSelect,
    setMultiSelect,
  } = useContext(EnigmaStudioContext);

  const [, setStorageList] = useState<ListResult | null>(null);
  const [storageTable, setStorageTable] = useState<
    Record<string, StorageReference>
  >({});

  const getStorageList = useCallback(() => {
    const path = "enigma-studio/" + userId;
    if (!path) return;
    if (!cloudStorage) return;
    const storageRef = ref(cloudStorage, path);
    listAll(storageRef).then((res) => {
      setStorageList(res);
      const _storageTable: Record<string, StorageReference> = {};
      res.items.forEach((item) => {
        _storageTable[item.name] = item;
      });
      setStorageTable(_storageTable);
    });
  }, [cloudStorage, userId]);

  const onLoadText = useCallback(
    async (doc: DocFile & Metadata, textRef: StorageReference) => {
      if (!textRef) return;
      if (!doc) return;
      if (doc.type !== 'text') return;
      const key = textRef.name;
      const dictKey = "user:" + key;

      if (enabledUserDictionary.find((d) => d.key === key)) {
        setSelectedDictionaries([...selectedDictionaries, "user:" + key]);
        return;
      }
      const blob = await getBlob(textRef);
      const text = await blob.text();
      loadText(dictKey, text);
      const name = doc.name;
      setEnabledUserDictionary([
        ...enabledUserDictionary,
        {
          key,
          name,
          headerRow: doc.headerRow,
        },
      ]);
      if (!multiSelect) {
        setSelectedDictionaries([dictKey]);
      } else {
        setSelectedDictionaries([...selectedDictionaries, "user:" + key]);
      }
    },
    [
      enabledUserDictionary,
      loadText,
      multiSelect,
      selectedDictionaries,
      setEnabledUserDictionary,
      setSelectedDictionaries,
    ]
  );

  const toggleText = useCallback(
    (doc: DocFile & Metadata, textRef: StorageReference) => {
      const key = textRef.name;
      const dictKey = "user:" + key;
      if (selectedDictionaries.includes(dictKey)) {
        setSelectedDictionaries(
          selectedDictionaries.filter((d) => d !== dictKey)
        );
      } else {
        onLoadText(doc, storageTable[key]);
      }
    },
    [onLoadText, selectedDictionaries, setSelectedDictionaries, storageTable]
  );

  useEffect(() => {
    getStorageList();
  }, [userId, getStorageList, fileListData]);

  useEffect(() => {
    if (!storageTable) return;
    if (!selectedDictionaries) return;
    if (fileListData?.files === undefined) return;
    selectedDictionaries.forEach((key) => {
      if (key.startsWith("user:")) {
        const fileName = key.replace("user:", "");
        if (!enabledUserDictionary.find((d) => d.key === fileName)) {
          const item = storageTable[fileName];
          if (item) {
            onLoadText(
              fileListData?.files[fileName] as DocFile & Metadata,
              item
            );
          }
        }
      }
    });
  }, [
    selectedDictionaries,
    storageTable,
    onLoadText,
    fileListData?.files,
    enabledUserDictionary,
  ]);

  if (tabValue !== "cloudFile") {
    return null;
  }
  if (fileListLoading) {
    return <div>loading</div>;
  }

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      {mode === "list" ? (
        <>
          <Button
            variant="contained"
            onClick={() => {
              setMode("new");
            }}
          >
            新規作成
          </Button>
          {
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>複数選択</Typography>
              <Switch
                checked={multiSelect}
                onChange={() => setMultiSelect(!multiSelect)}
              />
            </Stack>
          }
          <CloudItemList
            userId={userId}
            fileListData={fileListData}
            setFileListData={setFileListData}
            selectedDictionaries={selectedDictionaries}
            setSelectedDictionaries={setSelectedDictionaries}
            storageTable={storageTable}
            toggleText={toggleText}
            getStorageList={getStorageList}
          />
        </>
      ) : (
        <CloudItemNew
          userId={userId}
          fileListData={fileListData}
          setFileListData={setFileListData}
          onClose={() => {
            setMode("list");
          }}
        />
      )}
    </Box>
  );
};
